//.modal-open .modal,
#resModal:target {
opacity:1;
display:block;
overflow-x: hidden;
overflow-y: auto;
}


//.modal-open .modal .modal-dialog,
 #resModal:target .modal-dialog {
transform: translate(0 ,0) ;

}
#resModal:target .modal-dialog{
transition-duration: 0s;
}
.modal{
  background-color: hsla(0,0,0,.3);
}
.modal-open main{

  filter: blur(2px);
}

a.close {
padding: 0;
cursor: pointer;
background: transparent;
border: 0;
-webkit-appearance: none;
}
.modal a.btn-secondary{
color:white;

}
