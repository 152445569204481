$atys-animation-speed : .4s;
$atys-breakpoint: 2800px;
$grid-breakpoints: (
// Extra small screen / phone
xs: 0,
// Small screen / phone
sm: 34em,
// Medium screen / tablet
md: 48em,
// Large screen / desktop
lg: 62em,
// Extra large screen / wide desktop
xl: 75em
) !default;
$sm-width: 34em;
$md-width: 48em;
$lg-width: 62em;

$padding: .325em;

$color_primary:#fff;
$color_secondary:#dd4814;
$gray : #888;

.sr-only {
  display:none;
}


.AtysNavbar {
  //  background-color:#fff;
  display: flex;
  flex-flow:row wrap;
  align-items: center;
  padding-top:$padding;
  padding-bottom:$padding;
  // border-bottom: 2px solid #eee;
  // margin-bottom:$padding;
  position:relative;
  .AtysNavbar-brand{
    display:flex;
    align-items: flex-end;
    color:$brand-primary;
    &:hover, &:focus{
      text-decoration: none;
    }


  }
  @media screen and (max-width: $atys-breakpoint) {

  }
}

.AtysNavbar-spacer {
  flex: 1;
}

.AtysNavbar-searchIcon{
  display:none;
  color:$gray;
  @media screen and (max-width: $atys-breakpoint) {
    display: block;
    margin-right:$padding * 2;
  }
}

.AtysNavbar-searchForm {
  margin-right:$padding;
  display:flex;


  &:not(:first-child) {
    border-left: 0;
  }
  &:not(:last-child) {
    border-right: 0;
  }
  & > * {
    border: 1px solid rgba(147, 128, 108, 0.25);
    padding: ($padding / 2) $padding;
  }

  & > *:first-child {
    border-radius: 2px 0 0 2px;
  }
  & > *:last-child {
    border-radius: 0 2px 2px 0;
  }
  input {

    flex:1 1 100%;
    width:70%;
    border-right: 0;


  }
  input, button{
    transition: opacity .6s ease;
    opacity:.8;
  }

  @media screen and (max-width: $atys-breakpoint) {

    margin-right:0;
    display:none;

    input {

    }
  }
}

.AtysNavbar-menuIcon {
  display: none;
  border: none;
  background: none;
  position: relative;
  margin-bottom: 0;
  padding: 1em;
  overflow: hidden;
  z-index: 15;
  @media screen and (max-width: $atys-breakpoint) {
    display: block;
  }

  span {
    height: 3px;
    width: 100%;
    display: inline-block;
    background-color: $gray;
    position: absolute;
    left: 0;
    transition-property: transform;
    transition-duration: $atys-animation-speed;
  }
  // outline: 1px solid red;
  span:first-child {
    top: 0.3em;
    transform-origin: top left;
  }
  span:last-child {
    bottom: 0.3em;
    transform-origin: bottom left;
  }
  span:nth-child(2) {
    top: calc(50% - 1.5px );
  }
}

.AtysNavbar-nav {
  display:flex;
  flex-flow: row wrap;
  text-align: center;
  transition: none;
  & > .nav-item{
    display: flex;
    align-items: center;
    margin-left:$padding;


  }
  & > div {
    float:left;
  }
  @media screen and (max-width: $atys-breakpoint) {
    flex-flow: column;
    align-items: center;
    padding: 1em;
    padding-top: 4em; //navbarheight
    position: fixed;
    background-color: hsla(204, 94%, 93%,1);
    background-color:$brand-primary;
    right: 0;
    top: 0;
    left:50%;
    bottom: 0;
    transform: translate(100%,0);
    transition: transform ease $atys-animation-speed;
    z-index: 10;
    max-height: 100vh;
    overflow: auto;
    .nav-item{
      margin-bottom:$padding;
    }
    .nav-item {
      float: none;
      display: block;
      margin-left: 1em;
      color:#fff;
      font-size:1rem;
    }

  }

}




// animation
.AtysToggle.AtysToggle-on{
  @media screen and (max-width: $atys-breakpoint) {
    // &.AtysNavbar-menuIcon span{
      //     background-color: #fff;
    //   }
    &.AtysNavbar-menuIcon span:first-child {
      transform: translate(.4em,-.1em) rotate(45deg);
    }
    &.AtysNavbar-menuIcon span:last-child {
      transform: translate(.4em,.1em) rotate(-45deg);
    }
    &.AtysNavbar-menuIcon span:nth-child(2) {
      opacity: 0;
    }
    & + .AtysNavbar-nav {
      transform: translate(0%,0);
    }
    & + .AtysNavbar-searchForm {
      display:flex;
      flex:1 1 100%;

      width:100%;
      order: 10;
      margin-top:$padding;
      z-index:1000;


    }

  }
}
.AtysNavbar-searchForm  input:focus, .AtysNavbar-searchForm  input:focus + button{
  opacity:.99;

}
// no-js support
.AtysNavbar-nav:target {
  transform: translate(0%,0);
}
.AtysNavbar-searchForm:target {
  display:flex;
  flex:1 1 100%;

  width:100%;
  order: 10;
  margin-top:$padding;



}



// navbar site code
.nav-lang a{
  text-decoration: none;
}

.nav-lang span{
  display:none;

}
.nav-lang img {
  margin:1em;
  width:25px;
}

html[lang=nl] .nav-lang a.nl img{
  width:40px;
}
html[lang=de] .nav-lang a.de img{
  width:40px;
}
html[lang=en] .nav-lang a.en img{
  width:40px;
}
.nav-lang img{
opacity:.3;

}
.nav-lang a img{
opacity:1;
}

.AtysNavbar {
  padding-top:8px;
  padding-bottom: 0;
  align-items: flex-start;

  .nav-item{
    color:$gray-dark;
    font-size:1.2em;
    margin-top:.125em;
    margin-bottom:.5em;
    margin-left:0;
  }

  .AtysNavbar-brand {
    display: flex;
    // align-items: flex-end;
    align-self: flex-end;

    img {
      height: 80px;
    }


  }
  .payoff {
    margin-bottom: -.12em;
    line-height: 1em;
    padding: 0;
    font-size: 2.2em;
    font-weight: 600;
    text-align: center;
    padding-bottom: 0;
    // z-index hack
    opacity: 0.99;
    z-index: 5;
    text-align: left;


  }

  .AtysNavbar-searchForm > *{
    border:1px solid $brand-primary;

  }
  .AtysNavbar-searchIcon, .AtysNavbar-menuIcon{


  }
  .AtysNavbar-searchIcon{
    color:$brand-primary;
    font-size: 1.4em;
    padding-bottom: 2px;


  }
.icon-mobile{
    display:none;
  }

  .AtysNavbar-searchForm button{
    color:$brand-primary;
  }
  .AtysNavbar-menuIcon span{
    background-color: $brand-primary;

  }
  .AtysNavbar-nav {
    background-color:#fff;
    text-transform: uppercase;
    background-image: url("images/lantaren.svg");
    background-position: center bottom;
    background-repeat: no-repeat;
    background-size: 50vh;
    h3{
      margin-top:1em;
    }

  }
  .AtysNavbar-navHeading{
    margin-top:1em;
  }
  @include media-breakpoint-down(sm) {
    .AtysNavbar-brand{
      //      max-width:40%;
    }
    .payoff{
      padding-left:.25em;
      font-size: 1.2rem;
      display:none;
    }
    .AtysNavbar-nav {
      background-color:#fff;
      left:0;
    }
    .AtysNavbar-menuIcon span{
      // background-color: #fff;
    }
     .icon-mobile{
      display:block;
      margin-bottom: -.12em;
    }
  }
}
