/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
 */
/*
Created on : Nov 25, 2015, 3:18:47 PM
Author     : ben
 */
/* lichtblauw cdebf5, rood: d22403 color-rechts: #3385ad* donkerblauw;#070766  orange: #FBD75C*/
$atys-breakpoint: 2800px;
$enable-flex: true;
$enable-gradients: true;
$enable-transitions: true;
$brand-primary: #d22403;
$brand-secondary: #3385ad;
// $brand-tertiary: #fbd75c;

$brand-tertiary: #facb2a;
$brand-quaternary: hsla(189, 65%, 82%, 0.5); // #afe1e9; //#cdebf5; #b3e6ef
$atys-border-radius: 0.25em;
@import "__bootstrap.scss";
@import "atys-navbar.scss"; // @import "atys-navbar_oud.scss";
/**** fixed footer */
body {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  font-family: Lato;

  & > main {
    flex: 1;
    background-image: url("images/lieverdje_half.svg");
    background-repeat: no-repeat;
    background-size: 200px;
    background-position: bottom right;
  }

  & > header {
    border-bottom: 2px $brand-primary solid;
    max-height: 90px;
    position: relative;

    &::before {
      @extend .bgi-gevels;
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      z-index: -1;
      top: 0;
      bottom: 0;
      // filter:blur(0px);
      // filter: drop-shadow(1px 1px 1px black);
      // filter: opacity(30%);
      opacity: 0.3;
    }
  }

  & > footer {
    background-color: $brand-primary;
    color: #fff;
    font-size: 0.9em;
    padding: $grid-gutter-width-base;

    a {
      color: inherit;
      color: hsla(250, 100%, 100%, 0.9);

      &:hover {
        color: inherit;
      }
    }
  }
}
// end fixed footer
//
/* form inline */
/* inline labels */
.home_car {
  .carousel-item {
    height: 380px;
    background-size: cover;
    // background-size: 100% .5em, cover;
    background-position: center bottom;

    & > div {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      color: #fff;
    }

    section {
      align-self: flex-end;
      text-shadow: #000 1px 1px 2px, #000 1px -1px 2px, #000 -1px -1px 2px,
        #000 -1px 1px 2px;
      a {
        color: inherit;
        &:hover {
          text-decoration: none;
        }
      }
    }
    h1 small {
      font-size: 70%;
      display: block;
      margin-top: 0.5rem;
    }
    @include media-breakpoint-down(md) {
      h1,
      h2 {
        font-size: 2.3rem;
      }

      h3 {
        font-size: 1.5rem;
      }
    }
  }

  div.spinner-buttons {
    text-align: center;
    margin-top: -1.2em;
    z-index: 4;
    opacity: 0.99;

    a {
      margin: 0.125em 1em;
    }
  }
}

.FormGroupInline {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  margin-bottom: 0.2em;

  label {
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
    margin: 0 1em 0 0;
    padding-bottom: 0.2em;
    text-transform: uppercase;
    font-size: 0.8em;
  }

  .FormSubGroupInline {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: space-around;
  }

  > *:first-child {
    width: 180px;
    text-align: right;
  }

  > *:last-child {
    flex: 1;
    min-width: 200px;
  }

  .form-control {
    width: auto;
  }

  .mce-container {
    flex: 1;
    border-radius: $atys-border-radius;
  }

  *:required {
    border-right-color: red;
  }

  &.formrequired {
    border-right-color: red;
    border-right: 1px solid red;
  }
}
.home_form {
  *:required {
    border-right-color: red;
  }
}
.row a.btn-primary {
  color: #fff;
}

img.float-md-left,
img.float-md-right {
  margin-top: 0.375em;
  margin-bottom: 0.375em;
}
@include media-breakpoint-up(sm) {
  img.float-md-left {
    padding-right: 1em;
  }

  img.float-md-right {
    padding-left: 1em;
  }
}

.searchresults mark {
  padding: 0;
}

.col-md-3,
.col-md-4,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9 {
  padding-right: 15px;
  padding-left: 15px;
}

h2 {
  color: $brand-primary;
}

.brand-secondary {
  color: $brand-secondary;
}

.bg-secondary {
  .media-body {
    background-color: #fff;
    color: $brand-secondary;
  }
}

.bg-tertiary {
  background-color: $brand-tertiary;
}

.card-vvv,
.card-contact {
  color: darken($brand-secondary, 0%);
}

.card-review {
  h5 {
    font-size: 1rem;
    font-weight: bold;
    margin-bottom: 0;
  }
  div {
    padding: 0.5em;
    text-align: center;
    border: 1px solid $brand-tertiary;
    p {
      margin: 0;
    }
    a {
      text-decoration: none;
    }
  }
}

// category tours
ul.bulletpoints {
  font-size: 1.1rem;
  // padding:1em 1em 1em 2em;
  @extend .alert-info;
  @extend .alert;
  padding-left: 35px;
  li {
    list-style: none;
  }

  li:before {
    font-family: "FontAwesome";
    content: "\f00c";
    margin: 0 10px 0 -1.5em;
    color: darken($brand-tertiary, 10%);
  }
}

ul.foodmenu {
  max-width: 600px;
  margin: 1em auto;
  list-style: none;
  text-align: center;
  padding: 0 1em;
  color: $gray-light;
  strong {
    display: block;
    font-weight: normal;
    letter-spacing: 0.2em;
    text-transform: uppercase;
    margin: 1.5em 0 0.5em;
  }
  br {
    font-size: 0.2em;
  }
  em {
    line-height: 1.3em;
    display: block;
    margin: 0.5em 0 0.5em;
    font-family: cursive;
    color: $gray-dark;
  }
  li:last-child {
    margin-bottom: 2em;
  }
}

summary {
  padding-top: 0.7em;
  padding-bottom: 0.7em;
  padding-left: 0.7em;
  color: $brand-tertiary;

  h4.alert-heading {
    font-style: italic;
    line-height: 2em;
    display: inline-block;
    color: $brand-secondary;
    &:hover {
      text-decoration: underline;
    }
  }

  &:hover {
    cursor: help;
  }

  &:focus {
    outline: none;
  }
}

h1.category img {
  float: right;
}
// category icons

div.show_categories {
  display: flex;
  margin-bottom: 1em;
  justify-content: flex-start;
  // filter:hue-rotate(190deg) saturate(60.2%) brightness(175%);
  a {
    width: 30px;
    height: 30px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    opacity: 0.25;
    //      filter:saturate(1%);
    margin-left: 0.825em;
    display: block;

    &:hover {
      opacity: 0.7;
    }
  }
  a.category {
    overflow: hidden;
    color: transparent;
    text-indent: -99em;
  }
  a.category-in {
    //  filter:saturate(100%);
    opacity: 1;
  }
}
.p_3 div.show_categories {
  justify-content: flex-end;
}

.sidebar {
  ul {
    padding-left: 1.5em;
  }

  li {
    list-style: none;
  }

  h4 {
    color: $brand-primary;
  }

  li:before {
    font-family: "FontAwesome";
    content: "\f005";
    content: "\f111";
    margin: 0 0.4em 0 -1.5em;
    color: darken($brand-tertiary, 10%);
    color: $brand-tertiary;
    font-size: 0.8em;
  }

  .card-title {
    // background-color:$brand-quaternary;
    border-top-right-radius: 4em 1em;
    padding: 0.5em 0.875em 0.4em;
    margin-left: -0.875em;
    margin-right: -0.875em;
  }

  .fa {
    color: darken($brand-tertiary, 10%);
    margin-right: 5px;
  }

  .card {
    background-color: hsla(100, 50%, 100%, 0.1);
    border-top-right-radius: 5em 2em;
    border-color: $brand-quaternary;
    padding-top: 0;
    margin-bottom: 2em;
  }

  .tagcloud {
    text-align: justify;
    font-size: 0.7em;
    line-height: 1.8em;
    a {
      padding: 4px;
    }
  }
  img {
    max-width: 100%;
  }
}

a.plainlink {
  color: inherit;
  text-decoration: none;
}
// .container .home2 {
//     margin: 20em 1em 1em;
//   @include media-breakpoint-down(sm) {
//   margin-top:5em;
//    }
//     h1,
//     p {
//         background-color: hsla(0, 50%, 100%, .7);
//         padding: 0.275em;
//         display: inline-block;
//     }
// }
//home

main {
  //  background-color:$brand-quaternary;
}

.bgi-1 {
  background-image: url("images/lantaren.svg");
  background-repeat: no-repeat;
  background-position: right bottom;
  background-size: 60%;
}
.bgi-2 {
  background-image: url("images/lantaren.svg");
  background-repeat: no-repeat;
  background-position: left bottom;
  background-size: auto 85%;
}

.bgc-0 {
  background-color: hsla(100, 50%, 100%, 0.1);

  & > h1 {
    //  margin-top:.625em;
  }
}

.sidebar {
}

dd {
  padding-left: 1em;
  padding-bottom: 0.875em;
}

dt {
  font-size: 1.25rem;
  // color: $brand-secondary;
}

.carousel-page {
  max-width: 800px;
  margin: 1em 0;
  border: 2px solid $brand-quaternary;
  padding: 1px;
}

.carousel-comment .carousel-item {
  font-style: italic;
}

.socialmedia a {
  color: $brand-secondary;
}
// .carousel_home {
//     /* Fade transition for carousel items */
//     .carousel-inner {
//         height: 350px;
//     }
//
//     .carousel-inner > .carousel-item.active.right,
//     .carousel-inner > .carousel-item.next {
//         left: 0;
//         transform: translate3d(150%, 0, 0);
//     }
//
//     .carousel-inner > .carousel-item.active.left,
//     .carousel-inner > .carousel-item.prev {
//         left: 0;
//         transform: translate3d(-150%, 0, 0);
//     }
//
//     .carousel-item {
//         transition: transform 1s ease;
//     }
//
//     .carousel-control {
//         background-image: none !important;
//         // [> remove background gradients on controls <]
//     }
//     //[> Fade controls with items <]
//     //.next.left,
//     //.prev.right,
//     //.carousel-item.active {
//     //opacity: 1;
//     //}
//     //.active.left,
//     //.active.right,
//     //.carousel-item {
//     //}
//     .jumbotron {
//         height: 300px;
//         width: 100%;
//     }
//     height: 400px;
// }
//Backgrounds

.bgi-brug {
  background-image: url("images/bg_toren2.svg");
  background-position: 0 bottom;
  background-repeat: no-repeat;
  background-size: 40vw;
}

.bgi-gevels {
  background-image: url("images/gb_trans_8.png");
  background-image: url("images/out3-85-2.png"),
    linear-gradient(0, hsla(200, 5%, 64%, 0.7) 30%, hsla(200, 5%, 64%, 1));
  background-image: url("images/out3-85-2.png");
  background-image: url("images/lea_klein.png");
  // background-image: linear-gradient( 45deg, blue, red );
  background-repeat: repeat-x;
  background-position: center bottom;
  background-size: auto 80%;
}

.bgi-lantaren {
  background-image: url("images/lantaren.svg");
  background-repeat: no-repeat;
  background-size: 50vw;
  background-position: 80% top;
}
// dt,dd {
//   display: inline;
// }
// dt {
// display: inline-block;
// width:100px;
// text-align: right;
// padding-right: 1em;
// }
// dd::after {
// 	content: "\A";
// 	white-space: pre;
// }
//
// dd + dd::before {
// 	content: ', ';
// 	font-weight: normal;
// 	margin-left: -.25em;
// }

.home_categories a .media-body p:last-child {
  margin-bottom: 2em;

  &::after {
    content: " >>>";
    color: $brand-primary;
    font-size: 1.4em;
    display: inline-block;
    line-height: 1em;
    vertical-align: bottom;
    padding-left: 1rem;
  }
}

.white {
  padding-top: $grid-gutter-width-base;
  padding-bottom: $grid-gutter-width-base;

  h1 {
    color: $brand-primary;
  }
}

.lyellow {
  padding-top: $grid-gutter-width-base;
  padding-bottom: $grid-gutter-width-base;

  background-color: transparentize($brand-tertiary, 0.2);
  background-color: $brand-primary;
  background-color: #fff;
  h3 {
    color: white;
    color: $brand-primary;
    font-size: 1.5em;
  }
  border-bottom: $grid-gutter-width-base * 1.5 solid
    transparentize($brand-quaternary, 0.2);
}

.lblue {
  padding-top: $grid-gutter-width-base;
  padding-bottom: $grid-gutter-width-base;
  // background: url('data:image/svg+xml,<svg viewBox="0 0 200 100" xmlns="http://www.w3.org/2000/svg"><polygon points="0,0 100,100 200,0" fill="%23fff" /></svg>') top/1em auto repeat-x, url('data:image/svg+xml,<svg viewBox="0 0 200 100" xmlns="http://www.w3.org/2000/svg"><polygon points="0,100 100,0 200,100" fill="%23fff" /></svg>') bottom/1em auto repeat-x;
  background-color: transparentize($brand-quaternary, 0.2);
  color: #fff;

  h1 {
    color: $brand-primary;
  }

  h2,
  h3,
  h4,
  h5 {
    color: inherit;
  }
}

.features li::before {
  content: "✓";
  position: absolute;
  top: 0.05em;
  right: 100%;
  margin-right: 0.1em;
  font-size: 320%;
  line-height: 1;
  color: $brand-secondary;
}

.parallax {
  // position: absolute;
  // top:0;
  // right:0;
  // left:0;
  // bottom:0;
  background-image: url("/uploads/background/scheepvaartmuseum_stella_0110-1.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  background-size: cover;
  height: 800px;
  transform: translateZ(-1px);
}

.home_categories {
  h2,
  h2,
  h3,
  h4 {
    color: inherit;
  }

  .fa {
    color: $brand-primary;
  }
}

.homeTours {
  color: $gray-dark;
  display: flex;
  flex-flow: row wrap;
  align-items: stretch;

  > a {
    padding-left: 0.5%;
    padding-right: 0.5%;
    display: flex;
    width: 25%;
    text-align: center;
    @include media-breakpoint-down(md) {
      width: 33%;
    }
    @include media-breakpoint-down(sm) {
      width: 50%;
      padding-left: 1%;
      padding-right: 1%;
      .card-block {
        padding-left: 0.225em;
        padding-right: 0.225em;
      }
    }
    //   @include media-breakpoint-down(xs) {
    // width: 100%;
    //   }
  }
  a:hover {
    text-decoration: none;
  }
  a:hover .card {
    outline: 1px solid $brand-secondary;
  }

  .card-block {
    padding: 0.675em;
    display: flex;
    flex-flow: column;

    h4 {
      flex: 1;
    }
    .badge--price {
      line-height: 1em;
      padding-top: 0.5em;
    }
    .content_bp {
      line-height: 1.2em;
      color: $gray-light;
      font-size: smaller;
      p:last-child {
        margin-bottom: 0;
      }
      ul {
        text-align: left;
        color: $gray-light;
        padding-left: 0.2em;
        list-style: none;
        margin-bottom: 0;
        li::before {
          padding-right: 4px;
          font-size: 14px;
          font-family: "fontAwesome";
          content: "\f00c";
          color: $brand-tertiary;
        }
      }
    }
  }

  .card {
    width: 100%;

    margin-bottom: 0.75rem;

    img {
      width: 100%;
      // max-width:100%;
    }
  }

  h4 {
    font-size: 1.1em;
  }

  a {
    color: $brand-secondary;
  }
}

.tour-list {
  display: flex;
  flex-direction: column;
  .homeTours {
    order: 2;
  }
  .price_now {
    order: 1;
  }
}

.badge-secondary {
  // background-color: $brand-primary;
  color: $brand-primary;
  color: $brand-secondary;
  color: $gray-light;
  font-weight: normal;
  opacity: 0.8;
  line-height: 1rem;
}
.badge-old {
  text-decoration: line-through;
  padding-top: 0;
}
.badge-now {
  color: $brand-primary;
  font-weight: bold;
  padding-bottom: 0;
}
.price_now {
  color: $brand-primary;
  //font-weight: bold;
  padding-top: 0.5em;
}
.badge-now:after {
  content: "* ";
}

.card-text a {
  color: $brand-secondary;
}
.card-title {
  word-break: normal;
}
/* price now */
tr.price_now {
  color: $brand-primary;
  font-weight: bold;
}
tr.price_now + tr {
  //text-decoration: line-through ;
  //opacity:.7;
}

.comment {
  margin-bottom: 0.375em;
}
.comments {
  position: relative;
}

.comments .media-body {
  position: relative;
  padding: 15px;
  border: 1px solid rgba(179, 230, 239, 0.5);
  border-radius: 1em;
}

//.comments:after,
.comments:before {
  position: absolute;
  top: 2em;
  left: -30px;
  right: 100%;
  width: 0;
  height: 0;
  display: block;
  content: " ";
  border-color: transparent;
  border-style: solid solid outset;
  pointer-events: none;
  border-right-color: rgba(255, 255, 255, 1);
  border-width: 20px;
  z-index: 3;
}

//.comments:after {
//border-width: 15px;
//border-right-color: #fff;
//margin-top: 0;
//margin-left: 1px;
//}

.comments .img-circle {
  width: 50px;
}

.comments h5 {
  color: $brand-tertiary;
  small {
    font-style: italic;
  }
}

// comments toggle
.comments-collapse .comment-long .media-body {
  max-height: 9.5em;
  overflow: hidden;
  position: relative;
  transition: max-height ease 0.5s;
}
.comments-collapse .comment-long::after {
  content: "\2913";
  position: absolute;
  text-align: right;
  bottom: 0;
  left: 0;
  right: 0;
  height: 1.5em;
  color: $brand-tertiary;
  padding-right: 0.75em;
  background-image: linear-gradient(
    to bottom,
    hsla(0, 100%, 100%, 0),
    hsla(0, 100%, 100%, 1)
  );
  cursor: pointer;
}

.comments-collapse .comment-long.AtysCollapse-show .media-body {
  max-height: 100em;
}

.comments-collapse .comment-long.AtysCollapse-show::after {
  background-image: none;
  content: "\2912";
}

// cooments scroller

div.comment:nth-child(n + 4) {
  display: none;
}

body > footer {
  h5 {
    text-transform: uppercase;
    font-size: 1.1em;
  }

  ul {
    padding-left: 0;
  }

  li {
    list-style: none;

    a {
      text-transform: capitalize;
    }
  }
}
// .rating {
//     unicode-bidi: bidi-override;
//     direction: rtl;
//     font-size: 30px;
// }
// .rating span.star {
//     font-family: FontAwesome;
//     font-weight: normal;
//     font-style: normal;
//     display: inline-block;
// }
// .rating span.star:hover:before, .rating span.star:hover ~ span.star:before {
//     content: "\f005";
//     color: #e3cf7a;
//   }
// .rating span.star:before {
//     content: "\f006";
//     padding-right: 5px;
//     color: #777777;
// }
//
// .rating {
//     float:left;
// }
/* :not(:checked) is a filter, so that browsers that don’t support :checked don’t
follow these rules. Every browser that supports :checked also supports :not(), so
it doesn’t make the test unnecessarily selective */
// .rating:not(:checked) > input {
//   position: absolute;
//   top: -9999px;
//   clip: rect(0,0,0,0);
// }
// .rating:not(:checked) > label {
//   float: right;
//   width: 1em;
//   padding: 0 0.1em;
//   overflow: hidden;
//   white-space: nowrap;
//   cursor: pointer;
//   font-size: 200%;
//   line-height: 1.2;
//   color: #ddd;
//
//   //  text-shadow:1px 1px #bbb, 2px 2px #666, .1em .1em .2em rgba(0,0,0,.5);
// }
/// .rating:not(:checked) > label:before {
//   content: '★ ';
// }
// .rating > input:checked ~ label {
//   color: $brand-tertiary;
//
//   //  text-shadow:1px 1px #c60, 2px 2px #940, .1em .1em .2em rgba(0,0,0,.5);
// }
// .rating:not(:checked) > label:hover,
// .rating:not(:checked) > label:hover ~ label {
//   color: gold;
//
//   //  text-shadow:1px 1px goldenrod, 2px 2px #B57340, .1em .1em .2em rgba(0,0,0,.5);
// }
// .rating > input:checked + label:hover,
// .rating > input:checked + label:hover ~ label,
// .rating > input:checked ~ label:hover,
// .rating > input:checked ~ label:hover ~ label,
// .rating > label:hover ~ input:checked ~ label {
//   color: #ea0;
//
//   // text-shadow:1px 1px goldenrod, 2px 2px #B57340, .1em .1em .2em rgba(0,0,0,.5);
// }
// .rating > label:active {
//   position: relative;
//   top: 2px;
//   left: 2px;
// }
//rating stars

.hblue {
  color: $brand-secondary;
}

.btn-tertiary {
  background-color: $brand-tertiary;
  border-color: $brand-tertiary;
  color: #fff;
}

.btn-secondary,
a.btn-secondary {
  background-color: $brand-secondary;
  border-color: $brand-secondary;
  color: #fff;
}

.stars-container {
  // font-size:2em;
  position: relative;
  display: inline-block;
  color: transparent;
}

.stars-container:before {
  position: absolute;
  top: 0;
  left: 0;
  content: "★★★★★";
  color: lightgray;
}

.stars-container:after {
  position: absolute;
  top: 0;
  left: 0;
  content: "★★★★★";
  color: $brand-tertiary;
  overflow: hidden;
}

.stars-0:after {
  width: 0;
}

.stars-10:after {
  width: 10%;
}

.stars-20:after {
  width: 20%;
}

.stars-30:after {
  width: 30%;
}

.stars-40:after {
  width: 40%;
}

.stars-50:after {
  width: 50%;
}

.stars-60:after {
  width: 60%;
}

.stars-70:after {
  width: 70%;
}

.stars-80:after {
  width: 80%;
}

.stars-90:after {
  width: 90%;
}
.stars-95:after {
  width: 95%;
}

.stars-100:after {
  width: 100;
}

p.logo-images {
  display: flex;
  flex-flow: row wrap;
  img {
    max-height: 40px;
    width: auto;
    margin: 0 1em 1em 0;
    object-fit: contain;
  }
}
div.logo-images {
  position: absolute;
  display: block;
  //width:100%;
  white-space: nowrap;
  will-change: transform;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-name: logoslider;
  animation-duration: 17s;
  //color:$brand-primary;
  img {
    max-height: 30px;
    width: auto;
    margin: 0 0.5em 0 0.5em;
    object-fit: contain;
  }
}
div.logo-images-container {
  overflow: hidden;
  position: relative;
  height: 30px;
}
//client section

@keyframes logoslider {
  0% {
    transform: translate3d(0%, 0, 0);
  }
  100% {
    transform: translate3d(-50%, 0, 0);
  }
}

//  .mainbgcontainer >div  a.btn{
//   background-color: red;
// background:  linear-gradient(-30deg, transparent 15px, $brand-primary 0)  bottom   right,  linear-gradient(-150deg, transparent 15px, $brand-primary 0)  top right;
// background-repeat: no-repeat;
//   background-size: 100% 50%;
//   color:#fff;
//   padding-right:2.5em;
// }
//color change
// main {
//   background-color: $brand-quaternary;
//
// }
// .sidebar .card{
//   background-color: #fff;
// }
// .bgc-0{
//   background-color: #fff;
//   padding-top:.5em;
//
// }
@import "_modal_fix.scss";

.modal-title img {
  padding-right: 0.25em;
}

.reservation-page [data-dismiss] {
  display: none;
}

body {
  #CDSRATINGWIDGET235,
  #CDSROWLOC235 {
    width: auto;
    // border-color:$brand-secondary;
    &.widEXC .bravoBox {
      // background-color: $brand-secondary;
    }
  }
}
